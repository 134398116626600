import React from "react";

import Wrapper from "./wrapper";

const url =
  "https://firebasestorage.googleapis.com/v0/b/collegiate-composites.appspot.com/o/example-photos%2F%%FILE%%.jpg?alt=media";
const example = (file) => url.replace("%%FILE%%", file);

export default ({ onClickNext }) => (
  <Wrapper button="Easy, next?" onClickNext={onClickNext}>
    <div className="grid gap-2 grid-cols-2">
      <img
        src={example("HeadTilt_Bad")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
      <img
        src={example("HeadTilt_Good")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
    </div>

    <ul className="mt-6 text-sm leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">Keep your head straight up and down.</div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">Don't tilt your head.</div>
      </li>
    </ul>
  </Wrapper>
);
