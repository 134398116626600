import React from "react";

import Wrapper from "./wrapper";

export default ({ onClickNext }) => (
  <Wrapper button="7-10 feet, I can do that" onClickNext={onClickNext}>
    <ul className="mt-8 leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">Stand 7-10 feet from the white wall</div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          If you need to sit, sit up straight, no slouching!
        </div>
      </li>
    </ul>
  </Wrapper>
);
