import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'

import rootReducer from './slices'

export const history = createBrowserHistory()

export default configureStore({
  reducer: rootReducer(history),
  middleware: [routerMiddleware(history), thunk],
})
