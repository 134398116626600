import React from "react";

import Wrapper from "./wrapper";

export default ({ onClickNext }) => (
  <Wrapper button="An iPhone 3G isn't gonna cut it" onClickNext={onClickNext}>
    <ul className="mt-6 leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          If you're uploading a previously taken photo, make sure the file is at
          least 750Kb.
        </div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          It's best to use the highest resolution on an iPhone or Android.
        </div>
      </li>
    </ul>
  </Wrapper>
);
