import { reduce } from 'lodash'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import event from './event'
import photo from './photo'
import user from './user'

const actions = {
  ...event.actions,
  ...photo.actions,
  ...user.actions,
}

export { actions }

export default history =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    event: event.reducer,
    photo: photo.reducer,
    user: user.reducer,
  })

export const connectActions = dispatch =>
  reduce(
    actions,
    (memo, func, name) => {
      memo[name] = (...args) => dispatch(func(...args))
      return memo
    },
    {}
  )
