import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'utilities'

import { PhoneNumber } from 'components'

const logo = require('assets/images/logo.png')

const DontShareLink = ({ event, user }) => {
  if (!event || !user) {
    return null
  }

  const { title, contact } = event

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <div className="bg-white px-4 py-2">
        <div className="w-full flex max-w-2xl mx-auto">
          <div className="pr-4 border-r border-gray-400">
            <img src={logo} width={44} height={40} alt="" />
          </div>
          <div className="truncate px-4 flex-1 text-sm text-gray-800 self-center">{title}</div>
        </div>
      </div>
      <div className="flex-1 py-4 px-4 md:px-0 flex  w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          <h1>
            Hey {user.firstName} {user.lastName}!
          </h1>
          <div className="border-t border-gray-400 my-4" />
          <p className="mt-8 text-sm">
            We want to make sure that you know that with this link, you are submitting a photo for{' '}
            {user.email}.
          </p>
          <p className="font-bold text-sm mt-8">
            Do not share this link or your photo will be overwritten!
          </p>
          <p className="text-sm mt-8">
            If you are not {user.firstName}, contact {contact.name} at
            <br />
            <PhoneNumber className="underline">{contact.phoneNumber}</PhoneNumber> for assistance.
          </p>
        </div>
      </div>
      <div className="p-4 bg-white  w-full max-w-2xl mx-auto">
        <Link to="/instructions" className="btn">
          Got it, I won't share my private link
        </Link>
      </div>
    </div>
  )
}

const mapStateToProps = ({ event, user }) => ({ event, user })
export default connect(mapStateToProps, DontShareLink)
