import React from 'react'
import firebase from 'firebase/app'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

firebase.initializeApp(config)
firebase.auth()
firebase.firestore()

// if (process.env.REACT_APP_CC_ENV === 'development') {
//   console.log('~~ LOCAL FIRESTORE ~~')
//   firebase.firestore().settings({
//     host: 'localhost:8080',
//     ssl: false,
//   })
// }

export default firebase

export const FirebaseProvider = ({ store, children }) => {
  const rrfProps = {
    firebase,
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true,
    },
    dispatch: store.dispatch,
    createFirestoreInstance,
  }

  return <ReactReduxFirebaseProvider {...rrfProps}>{children}</ReactReduxFirebaseProvider>
}
