import { createSlice } from '@reduxjs/toolkit'
import thunks from '../thunks'

const initialState = {
  finalURL: null,
  error: null,
}

export default createSlice({
  name: 'photo',
  initialState,
  reducers: {
    resetPhoto: () => initialState,
  },
  extraReducers: {
    [thunks.photos.uploadPhoto.fulfilled]: (state, { payload }) => {
      console.log('uploadPhoto fulfilled', payload)
      if (payload.error) {
        state.error = payload.error
      } else {
        state.finalURL = payload.urls.final
      }
    },
    [thunks.photos.uploadPhoto.rejected]: (state, { payload }) => {
      console.log('uploadPhoto rejected', payload)
      state.error = payload.error || 'Unfortunately there was an error, please try again.'
    },
  },
})
