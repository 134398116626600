import React from "react";

import Wrapper from "./wrapper";

const whiteWall = require("assets/images/white-wall.jpeg");

export default ({ onClickNext }) => (
  <Wrapper button="White wall, natural light. Next?" onClickNext={onClickNext}>
    <img
      src={whiteWall}
      alt="white wall"
      className="mx-auto"
      style={{ maxHeight: 280 }}
    />
    <ul className="mt-6 text-sm leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">Start with a white wall</div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          If possible, have it lit with natural light
        </div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          No lights directly above or next to your face
        </div>
      </li>
    </ul>
  </Wrapper>
);
