import React from 'react'
import { connect } from 'utilities'

import { Header, PhoneNumber } from 'components'

const Confirm = ({ photo, event, push }) => {
  if (!event) {
    push('/')
    return null
  }

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <Header headline="That's it!" />
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          <h2>Thanks! You're all done!</h2>
          <p className="text-sm mt-8">
            If you have any questions, please contact {event.contact.name} at{' '}
            <PhoneNumber>{event.contact.phoneNumber}</PhoneNumber> or{' '}
            <a href={`mailto:${event.contact.email}`}>{event.contact.email}</a>.
          </p>
          <p className="text-sm mt-8">
            If you'd like to download your photo, can you{' '}
            <a
              href={photo.finalURL}
              target="_blank"
              rel="noopener noreferrer"
              className="underline cursor-pointer"
            >
              click here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ event, photo }) => ({ event, photo })
export default connect(mapStateToProps, Confirm)
