import { reduce } from "lodash";
import { default as photos } from "./photos";

const thunks = {
  photos,
};

export default thunks;

export const connectThunks = (dispatch) =>
  reduce(
    thunks,
    (memo, funcs) => {
      const connectedFuncs = reduce(
        funcs,
        (_, func, name) => (memo[name] = (...args) => dispatch(func(...args))),
        {}
      );
      Object.assign(memo, connectedFuncs);
      return memo;
    },
    {}
  );
