import React, { Fragment } from 'react'

export const stripPhoneNumber = raw => `${raw}`.replace(/[^\d]+/g, '')

export const formatPhoneNumber = raw => {
  if (!raw) {
    return ''
  }

  const digits = stripPhoneNumber(raw)
  if (!digits) {
    return ''
  }

  const parts = digits.split(/(\d{3})(\d{3})(\d{4})/)
  return `(${parts[1]}) ${parts[2]}-${parts[3]}`
}

export default ({ children, link }) => {
  const formatted = formatPhoneNumber(children)
  const display = <Fragment>{formatted}</Fragment>
  if (link) {
    return <a href={`tel:${children}`}>{display}</a>
  }
  return display
}
