import React from "react";

const logo = require("assets/images/logo.png");

export default ({ headline }) => (
  <div className="bg-white px-4 py-2">
    <div className="w-full flex max-w-2xl mx-auto">
      <div className="pr-4 border-r border-gray-400">
        <img src={logo} width={44} height={40} alt="" />
      </div>
      <div className="truncate px-4 flex-1 text-sm text-gray-800 self-center">
        {headline}
      </div>
    </div>
  </div>
);
