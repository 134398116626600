import React from "react";

import Wrapper from "./wrapper";

export default ({ onClickNext }) => (
  <Wrapper
    button="I will not take a selfie, even with a stick"
    onClickNext={onClickNext}
  >
    <ul className="mt-8 text-sm leading-6">
      <li className="mb-4 flex">
        <div>
          <span role="img" aria-label="x">
            ❌
          </span>
        </div>
        <div className="flex-1 ml-3">I will not take a selfie</div>
      </li>
      <li className="mb-4 flex">
        <div>
          <span role="img" aria-label="x">
            ❌
          </span>
        </div>
        <div className="flex-1 ml-3 italic">I will not take a selfie</div>
      </li>
      <li className="mb-4 flex">
        <div>
          <span role="img" aria-label="x">
            ❌
          </span>
        </div>
        <div className="flex-1 ml-3 font-bold">I will not take a selfie</div>
      </li>
      <li className="mb-4 flex mt-8">
        <div>✓</div>
        <div className="flex-1 ml-3">
          Set up a tripod or stand, holding your phone around shoulder height.
        </div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-3">
          Otherwise, have someone take your photo from a safe distance.
        </div>
      </li>
    </ul>
  </Wrapper>
);
