import React from "react";

import Wrapper from "./wrapper";

const url =
  "https://firebasestorage.googleapis.com/v0/b/collegiate-composites.appspot.com/o/example-photos%2F%%FILE%%.jpg?alt=media";
const example = (file) => url.replace("%%FILE%%", file);

export default ({ onClickNext }) => (
  <Wrapper button="Party pooper" onClickNext={onClickNext}>
    <div className="grid gap-2 grid-cols-2">
      <img
        src={example("Goofy")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
      <img
        src={example("Goofy2")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
    </div>

    <ul className="mt-6 text-sm leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          Make sure to wear the attire approved by your chapter.
        </div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          All images get reviewed by the national office, and therefore goofy,
          non-conforming or irreverent photos will not be included.
        </div>
      </li>
    </ul>
  </Wrapper>
);
