import React from 'react'
import { connect } from 'utilities'

const logo = require('assets/images/logo.png')

const TakePhoto = ({ event, user, push, actions, thunks }) => {
  const fileRef = React.createRef()

  if (!event || !user) {
    push('/')
  }

  const onFileSelect = e => {
    const [file] = e.target.files
    const fr = new FileReader()
    fr.onload = () => {
      thunks.uploadPhoto({
        eventId: event.id,
        userId: user.id,
        photo: fr.result,
      })
      push('/processing')
    }
    fr.readAsDataURL(file)
  }

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <div className="bg-white px-4 py-2">
        <div className="w-full flex max-w-2xl mx-auto">
          <div className="pr-4 border-r border-gray-400">
            <img src={logo} width={44} height={40} alt="" />
          </div>
          <div className="truncate px-4 flex-1 text-sm text-gray-800 self-center">
            Take a photo for the composite
          </div>
        </div>
      </div>
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          <ul className="mt-8 text-sm leading-6">
            <li className="mb-4 flex mt-8">
              <div>✓</div>
              <div className="flex-1 ml-3">White wall, 7-10 feet away</div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-3">No selfies!</div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-3">Body angled away, face turned towards camera</div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-3">No head tilt</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="p-4 bg-white w-full max-w-2xl mx-auto">
        <button onClick={() => fileRef.current.click()} className="btn w-full">
          Take a great photo!
        </button>
      </div>
      <input
        ref={fileRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={onFileSelect}
      />
    </div>
  )
}

const mapStateToProps = ({ event, user }) => ({ event, user })
export default connect(mapStateToProps, TakePhoto)
