import React from 'react'
import { connect } from 'utilities'

import { Header } from 'components'

const ProcessingError = ({ event, push, actions }) => {
  if (!event) {
    push('/')
  }

  const onTryAgain = () => {
    actions.resetPhoto()
    push('/take-photo')
  }

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <Header headline="Uh oh." />
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          <div className="text-red-600 font-bold">There was a problem with your photo</div>
          <hr className="my-4" />
          <p className="my-4">Common solutions:</p>
          <ul className="mt-4 text-sm leading-6">
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-2">Ensure your waist is in view.</div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-2">There's at least 6 inches of space above your head.</div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-2">
                Both shoulders have at least 6 inches of space to their sides.
              </div>
            </li>
            <li className="mb-4 flex">
              <div>✓</div>
              <div className="flex-1 ml-2">
                We will crop the photo properly, so don't worry about there being too much empty
                space.
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="p-4 bg-white w-full max-w-2xl mx-auto">
        <button onClick={onTryAgain} className="btn w-full">
          Let's try again!
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ event }) => ({ event })
export default connect(mapStateToProps, ProcessingError)
