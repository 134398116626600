import { createAsyncThunk } from '@reduxjs/toolkit'
import { storagePath } from 'utilities'

import firebase from '../firebase'

const getExt = photo =>
  photo
    .match(/^data:(image\/[a-z]+);/)[1]
    .replace('image/', '')
    .replace('jpeg', 'jpg')

const uploadPhoto = createAsyncThunk('photos/uploadPhoto', async ({ eventId, userId, photo }) => {
  const path = storagePath(eventId, userId)
  const ext = getExt(photo)

  const storageRef = firebase.storage().ref()
  const fileRef = storageRef.child(`${path}.original.${getExt(photo)}`)
  await fileRef.putString(photo, 'data_url')

  const safe = encodeURIComponent(`${path}.${ext}`)
  const uri = `/api/photos-process?path=${safe}`
  return await fetch(uri).then(r => r.json())
})

export default { uploadPhoto }
