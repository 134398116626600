import React, { useEffect, useRef, useState } from 'react'
import { number, string } from 'prop-types'
import classNames from 'classnames'

import './styles.css'

const ProgressBar = ({ totalSeconds, className }) => {
  const [seconds, setSeconds] = useState(0)
  const secondsRef = useRef(seconds)

  useEffect(() => {
    const interval = setInterval(() => {
      secondsRef.current++
      setSeconds(secondsRef.current)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      className={classNames(
        'progress-bar relative bg-gray-600 w-full rounded-lg border-2 border-gray-600 text-center',
        className
      )}
    >
      <div
        className={classNames('progress bg-gray-200 rounded-l-lg', {
          'rounded-lg': seconds >= totalSeconds,
        })}
        style={{ width: `${Math.min(100, (100 * seconds) / totalSeconds)}%` }}
      >
        &nbsp;
      </div>
      <div
        className={classNames('absolute inset-0 text-center text-sm', {
          'text-white': seconds / totalSeconds < 0.45,
        })}
      >
        {Math.round(seconds * 10) / 10}s / ~{totalSeconds}s
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  totalSeconds: number.isRequired,
  className: string,
}

export default ProgressBar
