import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { connectActions } from 'slices'
import { connectThunks } from 'thunks'

export default function (mapStateToProps, Component) {
  if (!Component) {
    Component = mapStateToProps
    mapStateToProps = null
  }

  const mapDispatchToProps = dispatch => {
    const actions = connectActions(dispatch)
    const thunks = connectThunks(dispatch)

    return {
      actions,
      thunks,
      push: uri => dispatch(push(uri)),
    }
  }

  let expandedMap
  if (!mapStateToProps) {
    expandedMap = ({ firebase }) => ({
      auth: firebase.auth,
    })
  } else {
    expandedMap = state => ({
      auth: state.firebase.auth,
      ...mapStateToProps(state),
    })
  }

  return connect(expandedMap, mapDispatchToProps)(withRouter(Component))
}
