import React, { useEffect, useState } from 'react'

const logo = require('assets/images/logo.png')

export default ({ button, children, onClickNext }) => {
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    setTimeout(() => setIsDisabled(false), 2000)
  }, [])

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <div className="bg-white px-4 py-2">
        <div className="w-full flex max-w-2xl mx-auto">
          <div className="pr-4 border-r border-gray-400">
            <img src={logo} width={44} height={40} alt="" />
          </div>
          <div className="truncate px-4 flex-1 text-sm text-gray-800 self-center">
            How to take your best picture
          </div>
          <div className="w-12" onClick={() => onClickNext(true)} />
        </div>
      </div>
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">{children}</div>
      </div>
      <div className="p-4 bg-white w-full max-w-2xl mx-auto">
        <button disabled={isDisabled} onClick={onClickNext} className="btn w-full">
          {button}
        </button>
      </div>
    </div>
  )
}
