import React from 'react'

import { Header } from 'components'

export default () => {
  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <Header headline="Well this is awkward..." />
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          <p className="text-sm my-3">
            You should have received a text message or email and a unique link for you to submit a
            picture.
          </p>
          <p className="text-sm my-3">
            If you haven't received either, please contact the president of your chapter.
          </p>
        </div>
      </div>
    </div>
  )
}
