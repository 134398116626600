import React, { useState } from 'react'
import { connect } from 'utilities'

import { Header } from 'components'

const Confirm = ({ photo, push, actions }) => {
  const [isLoading, setIsLoading] = useState()

  if (!photo.finalURL) {
    push('/take-photo')
  }

  const onStartAgain = () => {
    actions.resetPhoto()
    push('/take-photo')
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const safe = encodeURIComponent(photo.finalURL)
    const uri = `/api/photos-accept?path=${safe}`
    await fetch(uri)
    push('/finished')
  }

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <Header headline="You look great!" />
      <div className="flex-1 py-4 px-4 md:px-0 w-full max-w-2xl mx-auto bg-red h-full flex flex-col">
        <div className="flex-1 flex justify-center items-center relative">
          <img
            src={photo.finalURL}
            alt="preview"
            className="mx-auto absolute w-auto h-auto max-w-full max-h-full shadow"
          />
        </div>
      </div>
      <div className="p-4 bg-white w-full max-w-2xl mx-auto flex">
        <button disabled={isLoading} className="btn btn-gray mr-4" onClick={onStartAgain}>
          Start again
        </button>
        <button disabled={isLoading} onClick={onSubmit} className="btn flex-1">
          {isLoading ? 'Submitting...' : 'Submit photo'}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ photo }) => ({ photo })
export default connect(mapStateToProps, Confirm)
