import React, { useState } from 'react'
import { connect } from 'utilities'

import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
import Step4 from './step-4'
import Step5 from './step-5'
import Step6 from './step-6'
import Step7 from './step-7'
import Step8 from './step-8'
import Step9 from './step-9'

const steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9]

const Instructions = ({ push }) => {
  const [step, setStep] = useState(0)

  const onClickNext = (skip = false) => {
    if (step < 8 && skip !== true) {
      setStep(step + 1)
    } else {
      push('/take-photo')
    }
  }

  const Step = steps[step]
  return <Step onClickNext={onClickNext} />
}

export default connect(Instructions)
