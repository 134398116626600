import React from "react";

import Wrapper from "./wrapper";

const url =
  "https://firebasestorage.googleapis.com/v0/b/collegiate-composites.appspot.com/o/example-photos%2F%%FILE%%.jpg?alt=media";
const example = (file) => url.replace("%%FILE%%", file);

export default ({ onClickNext }) => (
  <Wrapper
    button="Waist up, no cutting off my forehead"
    onClickNext={onClickNext}
  >
    <div className="grid gap-2 grid-cols-2">
      <img
        src={example("Bad_Crop")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
      <img
        src={example("Good_Crop")}
        alt=""
        className="mx-auto"
        style={{ maxHeight: 280 }}
      />
    </div>

    <ul className="mt-6 text-sm leading-6">
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          Take the picture from the waist up, leaving some space to the side of
          your body and above your head.
        </div>
      </li>
      <li className="mb-4 flex">
        <div>✓</div>
        <div className="flex-1 ml-2">
          If the picture is from your shoulders up or too cropped, we won't be
          able to use it.
        </div>
      </li>
    </ul>
  </Wrapper>
);
