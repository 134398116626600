import React, { useEffect } from 'react'
import { connect } from 'utilities'

import { Header, ProgressBar } from 'components'

const Processing = ({ event, photo, push }) => {
  if (!event) {
    push('/')
  }

  useEffect(() => {
    if (!photo.finalURL) {
      return
    }
    push('/confirm')
  }, [photo.finalURL, push])

  useEffect(() => {
    if (photo.error) {
      push('/processing/error')
    }
  }, [photo.error, push])

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <Header headline="We're processing your photo" />
      <div className="px-4">
        <div className="p-4 mt-8 bg-white w-full max-w-2xl mx-auto flex flex-col">
          <p className="font-bold mb-2">Ready to be impressed?</p>
          <p className="text-sm">
            The good news is the photo is going to look amazing. Bad news? It might take 20 or even
            &lt;gasp&gt; 40 seconds to finish.
          </p>
          <ProgressBar totalSeconds={40} className="mt-8" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ event, photo }) => ({ event, photo })
export default connect(mapStateToProps, Processing)
