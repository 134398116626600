import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

import store, { history } from './store'
import { FirebaseProvider } from './firebase'

import Home from './containers/Home'
import Authenticate from './containers/Authenticate'
import DontShareLink from './containers/DontShareLink'
import Instructions from './containers/Instructions'
import TakePhoto from './containers/TakePhoto'
import Processing from './containers/Processing'
import ProcessingError from './containers/Processing/error'
import Confirm from './containers/Confirm'
import Finished from './containers/Finished'

function App() {
  return (
    <Provider store={store}>
      <FirebaseProvider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/-/:shortcode" component={Authenticate} />
            <Route exact path="/start" component={DontShareLink} />
            <Route exact path="/instructions" component={Instructions} />
            <Route exact path="/take-photo" component={TakePhoto} />
            <Route exact path="/processing" component={Processing} />
            <Route exact path="/processing/error" component={ProcessingError} />
            <Route exact path="/confirm" component={Confirm} />
            <Route exact path="/finished" component={Finished} />
          </Switch>
        </ConnectedRouter>
      </FirebaseProvider>
    </Provider>
  )
}

export default App
