import React, { useEffect, useState } from 'react'
import { find } from 'lodash'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { connect } from 'utilities'

const logo = require('assets/images/logo.png')

const ladder = steps => find(steps, 0)[1]

const Authenticate = ({ push, match, actions }) => {
  const firebase = useFirebase()
  const firestore = useFirestore()

  const [error, setError] = useState()
  const { shortcode } = match.params

  useEffect(() => {
    ;(async () => {
      try {
        const { eventId, userId } = await firestore
          .get({ collection: 'shortcodes', doc: shortcode })
          .then(res => res.data())

        await fetch(`/api/authenticationToken?uid=${userId}`)
          .then(res => res.json())
          .then(({ token }) => firebase.auth().signInWithCustomToken(token))

        const event = await firestore
          .get({ collection: 'events', doc: eventId })
          .then(res => Object.assign(res.data(), { id: eventId }))
        actions.setEvent(event)

        const user = await firestore
          .get({
            collection: 'events',
            doc: eventId,
            subcollections: [{ collection: 'users', doc: userId }],
          })
          .then(res => Object.assign(res.data(), { id: userId }))
        actions.setUser(user)

        push('/start')
      } catch (err) {
        console.error(err)
        setError("Unfortunately, we weren't able to match your code to a composite.")
      }
    })()
  }, [actions, firebase, firestore, push, shortcode])

  return (
    <div className="flex-1 flex flex-col bg-gray-200 w-full">
      <div className="bg-white px-4 py-2">
        <div className="w-full flex max-w-2xl mx-auto">
          <div className="pr-4 border-r border-gray-400">
            <img src={logo} width={44} height={40} alt="" />
          </div>
          <div className="truncate px-4 flex-1 text-sm text-gray-800 self-center">
            {error ? 'Error' : 'Loading...'}
          </div>
        </div>
      </div>
      <div className="flex-1 py-4 px-4 md:px-0 flex w-full max-w-2xl mx-auto">
        <div className="bg-white shadow flex-1 p-6 leading-6">
          {ladder([
            [error, <div className="text-red-600 font-bold">{error}</div>],
            [true, <div>Loading...</div>],
          ])}
        </div>
      </div>
      <div className="p-4 bg-white w-full max-w-2xl mx-auto">
        <button disabled className="btn btn-gray w-full">
          {error ? '- error -' : 'Loading...'}
        </button>
      </div>
    </div>
  )
}

export default connect(Authenticate)
